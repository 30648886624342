define("discourse/plugins/discourse-gamification/discourse/controllers/admin-plugins-show-discourse-gamification-leaderboards-index", ["exports", "@ember/controller", "@ember/object", "@ember/service", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse-common/utils/decorators", "I18n", "discourse/plugins/discourse-gamification/discourse/components/modal/recalculate-scores-form"], function (_exports, _controller, _object, _service, _ajax, _ajaxError, _decorators, _I18n, _recalculateScoresForm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _controller.default.extend((_dec = (0, _decorators.default)("model.leaderboards.@each.updatedAt"), (_obj = {
    modal: (0, _service.inject)(),
    dialog: (0, _service.inject)(),
    toasts: (0, _service.inject)(),
    creatingNew: false,
    sortedLeaderboards(leaderboards) {
      return leaderboards?.sortBy("updatedAt").reverse() || [];
    },
    resetNewLeaderboard() {
      this.set("creatingNew", false);
    },
    destroyLeaderboard(leaderboard) {
      this.dialog.deleteConfirm({
        message: _I18n.default.t("gamification.leaderboard.confirm_destroy"),
        didConfirm: () => {
          return (0, _ajax.ajax)(`/admin/plugins/gamification/leaderboard/${leaderboard.id}`, {
            type: "DELETE"
          }).then(() => {
            this.toasts.success({
              duration: 3000,
              data: {
                message: _I18n.default.t("gamification.leaderboard.delete_success")
              }
            });
            this.model.leaderboards.removeObject(leaderboard);
          }).catch(_ajaxError.popupAjaxError);
        }
      });
    },
    recalculateScores() {
      this.modal.show(_recalculateScoresForm.default, {
        model: this.model
      });
    },
    parseDate(date) {
      if (date) {
        // using the format YYYY-MM-DD returns the previous day for some timezones
        return date.replace(/-/g, "/");
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "sortedLeaderboards", [_dec], Object.getOwnPropertyDescriptor(_obj, "sortedLeaderboards"), _obj), _applyDecoratedDescriptor(_obj, "resetNewLeaderboard", [_object.action], Object.getOwnPropertyDescriptor(_obj, "resetNewLeaderboard"), _obj), _applyDecoratedDescriptor(_obj, "destroyLeaderboard", [_object.action], Object.getOwnPropertyDescriptor(_obj, "destroyLeaderboard"), _obj), _applyDecoratedDescriptor(_obj, "recalculateScores", [_object.action], Object.getOwnPropertyDescriptor(_obj, "recalculateScores"), _obj)), _obj)));
});